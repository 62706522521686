<template>
  <div>
    <LeadNotFound v-if="leadNotFound" />
    <container v-else style="max-width: 1000px">
      <v-row>
        <v-col>
          <v-card outlined>
            <v-toolbar elevation="0">
              <v-btn @click="$router.go(-1)" icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title class="primary--text">
                <span v-if="lead">
                  {{ __("Editar lead", "new_lead") }} -
                  {{ lead.identifier_code }}
                </span>
                <v-skeleton-loader v-else type="text"></v-skeleton-loader>
              </v-toolbar-title>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>

      <LeadForm @submit="updateLead" @cancel="$router.go(-1)" ref="leadForm" />

      <v-dialog v-model="loading" width="300" persistent>
        <v-card color="primary" dark>
          <v-card-text>
            {{ loadingProgressionText }}
            <v-progress-linear
              :value="loadingProgressionValue"
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </container>
  </div>
</template>

<script>
import LeadForm from "@/components/Lead/Form/LeadForm";
import LeadNotFound from "@/views/LeadNotFound";
import LeadMixin from "@/mixins/LeadMixin";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  components: {
    LeadForm,
    LeadNotFound,
  },

  mixins: [LeadMixin],

  data() {
    return {
      leadNotFound: false,
    };
  },
  computed: {
    lead() {
      return this.$store.getters["lead_form/getLead"];
    },
  },

  async created() {
    const leadNumber = this.$route.params.lead_number;

    this.$store.commit("setLoading", {
      loading: true,
    });

    try {
      await this.$store.dispatch("lead_form/loadEditLeadMetadata", {
        leadNumber,
      });
      this.$refs.leadForm.fill({
        ...this.lead,
        subtype_id: this.lead.subtype_id,
        insured: this.lead.participant_insured.contact,
        is_insured_participant: this.lead.participant_insured.insured,
        injured: this.lead.participant_injured?.contact,
        city: this.lead.risk_location?.city,
        city_id: this.lead.risk_location?.city?.id,
        city_area: this.lead.risk_location?.city_area,
        city_area_id: this.lead.risk_location?.city_area?.id,
        country_area: this.lead.risk_location?.country_area,
        country_area_id: this.lead.risk_location?.country_area?.id,
        postal_code: this.lead.risk_location?.postal_code,
        street: this.lead.risk_location?.street,
        number: this.lead.risk_location?.number,
      });
    } catch (error) {
      if (error.response.status == 404) {
        this.leadNotFound = true;
      }
    } finally {
      this.$store.commit("setLoading", {
        loading: false,
      });
    }
  },
};
</script>

<style></style>
